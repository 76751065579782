// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { clienteScripts } from "../../../scripts/clientes"
import { cfdiScripts } from "../../../scripts/cfdi"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCfdiMinimo: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Cancelar facturas</h2>
                <p>
                    Las facturas a cancelar se cancelan inmediatamente, <b>inclusive cuando la factura a cancelar tenga un periodo de confirmación por el receptor,</b> esta estará marcada como cancelada. <br />
                    Dicho periodo de cancelación aplica para facturas de más de 3'000 que se facturen varios dias después de haber sido elaboradas, de esta forma el receptor tiene la posibilidad de intervenir en la cancelación en el buzón tributario del SAT. 
                </p>
                <pre>
                    <code>
                        <b>POST:</b> https://api.fiscalpop.com/api/v1/cfdi/cancel/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h3>
                    Campos del Request
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>uuids [ ]:</b> Array con los UUIDs de cada factura que quieres cancelar, puede ser 1 o mas elementos.
                        </p>
                    </li>
                </ul>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>folios [ ]: </b> El bloque de folios que solicitaste cancelar, como Array
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">folios</span>.estatus:</b> Código de estatus en formato HTTP sobre la cancelación.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">folios</span>.uuid:</b> El UUID al que aplica el estatus del folio.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>
                            <b>fecha:</b> Fecha en formato JSON
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>rfcEmisor:</b> RFC del cliente emisor, quien canceló la factura.
                        </p>
                    </li>
                </ul>
                <SectionNavigate next={`/api/referencia/cfdi-find`} previous={`/api/referencia/cfdi-maximum`}/>
            </div>
            <div className="side">
                <CodeBlock title="CFDI Cancelar" requests={cfdiScripts.cancelar.requests} response={cfdiScripts.cancelar.response} />
            </div>
        </div>
    </Layout>
)

export default ApiCfdiMinimo
